import React, { useState, useRef, useEffect } from 'react'

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import { useHistory } from "react-router-dom";
import { FacebookProvider, CustomChat } from 'react-facebook';
import './styles/terms.scss'

function Terms(themeData) {

  useEffect(() => {
    document.title = 'Terms & Conditions • quickLink';
  }, []);

    const history = useHistory();
  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <FacebookProvider appId="1418855892064267" chatSupport>
          <CustomChat pageId="172444475943172" minimized={false}/>
        </FacebookProvider> 
        <div id='terms'>

          <section className="terms">
            <div className='pagetopMargin'></div>
            <div className="wrapper">
                <div className="flex flex-column">
                    <span className="quicklink-title">Terms & Conditions</span>
                </div>
            </div>
            <div className="wrapper">
              <div className='terms-content'>
                <div className='terms-title'>Acceptance of Terms and Conditions</div>
                <br/>
                <div className='terms-info'>By accessing or using the QuickLink digital business card service (referred to as "we," "us," or "our"), you agree to comply with and be bound by these Terms and Conditions. If you do not agree with these terms, please do not use our service.</div>
                <br/>
                <div className='terms-title'>Use of the Service</div>
                <br/>
                <div className='terms-info'>
                  <ul>
                    <li>You may use QuickLink to create and manage your digital business card and related services.</li>
                    <li>You agree not to misuse our service, including engaging in any activity that may disrupt or interfere with the service.</li>
                  </ul> 
                </div>
                <br/>
                <div className='terms-title'>Privacy</div>
                <br/>
                <div className='terms-info'>Your use of QuickLink is also governed by our Privacy Policy, which can be found at Privacy Policy page. Please review this policy to understand how we collect, use, and protect your personal information.</div>
                <br/>
                <div className='terms-title'>User Content</div>
                <br/>
                <div className='terms-info'>
                  <ul>
                    <li>You are solely responsible for the content you create, publish, and share using QuickLink.</li>
                    <li>We may remove or modify any user content that violates these Terms and Conditions or is otherwise objectionable.</li>
                    <li>The Client acknowledges that they have the responsibility to keep their digital business card information accurate and up-to-date.</li>
                  </ul> 
                </div>
                {/* <br/>
                <div className='terms-title'>Updating of Information</div>
                <br/>
                <div className='terms-info'>
                  <ul>
                    <li>The Client acknowledges that they have the responsibility to keep their digital business card information accurate and up-to-date.</li>
                    <li>A fee of 100 PHP will be charged for each update request initiated by the Client. This fee covers the cost of processing and implementing the changes to the digital business card.</li>
                    <li>The updating fee is non-refundable and must be paid in advance before any changes are made to the digital business card.</li>
                  </ul> 
                </div> */}
                <br/>
                <div className='terms-title'>Customized Cards</div>
                <br/>
                <div className='terms-info'>
                  <ul>
                    <li>Customization requests for digital business cards are subject to approval by QuickLink.</li>
                    <li>The Client agrees to provide detailed specifications for the customization, including but not limited to design preferences, color schemes, and additional content.</li>
                    <li>A non-refundable down payment of 50% of the total customization cost is required before any customization work begins.</li>
                    <li>The remaining 50% of the customization cost must be paid upon approval of the customized digital business card before it is made accessible to the Client.</li>
                    <li>Failure to provide the necessary details or the remaining payment within the specified timeframes may result in delays or cancellation of the customization request.</li>
                    <li>Customization fees are non-refundable once the customization process has commenced.</li>
                    <li>QuickLink reserves the right to refuse any customization request that violates ethical standards, legal regulations, or conflicts with the company's policies.</li>
                    <li>All customized cards will be delivered within 3-5 business days upon final approval of the designs by the client.</li>
                    <li>The client is responsible for booking and managing the shipping of the digital business cards. Upon the completion of the cards, the client will be notified. Any delays or issues related to the shipping service chosen by the client will not be the responsibility of QuickLink.</li>
                  </ul> 
                </div>
                <br/>
                <div className='terms-title'>Intellectual Property</div>
                <br/>
                <div className='terms-info'>
                  <ul>
                    <li>We do not claim ownership of the content you create using QuickLink. However, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, and distribute your content.</li>
                    <li>All rights, title, and interest in and to the QuickLink service are and will remain the exclusive property of QuickLink.</li>
                  </ul> 
                </div>
                <br/>
                <div className='terms-title'>Termination</div>
                <br/>
                <div className='terms-info'>We reserve the right to terminate or suspend your access to QuickLink at our sole discretion and without notice if you breach these Terms and Conditions.</div>
                <br/>
                <div className='terms-title'>Limitation of Liability</div>
                <br/>
                <div className='terms-info'>To the extent permitted by law, QuickLink shall not be liable for any indirect, incidental, special, or consequential damages.</div>
                <br/>
                <div className='terms-title'> Changes to These Terms and Conditions</div>
                <br/>
                <div className='terms-info'>We may update these Terms and Conditions to reflect changes to our service. If we make significant changes, we will notify you. Your continued use of QuickLink after such changes shall constitute your consent to the new terms.</div>
                <br/>
                <div className='terms-title'> Contact Us</div>
                <br/>
                <div className='terms-info'>If you have any questions or concerns regarding these Terms and Conditions, please contact us.</div>

              </div>
            </div>
            <div className='margin-bot'></div>
          </section>

        </div>
        </ThemeProvider>
        
    )
}

export default Terms;