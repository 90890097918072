import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'

const ModalForm = ({ isOpen, closeModal, children, themeData }) => {
  return (
    <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
    <GlobalStyles />
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
          }}
        >
          <motion.div className='modal-bg'
           
          >
            {/* <button onClick={closeModal} style={{ position: 'absolute', top: '0', right: '0' }}>
              Close
            </button> */}
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
    </ThemeProvider>
  );
};

export default ModalForm;