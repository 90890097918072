import React, { useState, useRef, useEffect } from 'react'

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import { useHistory } from "react-router-dom";
import { FacebookProvider, CustomChat } from 'react-facebook';
import './styles/privacy.scss'

function Privacy(themeData) {

  useEffect(() => {
    document.title = 'Privacy Policy • quickLink';
  }, []);

    const history = useHistory();
  
    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <FacebookProvider appId="1418855892064267" chatSupport>
          <CustomChat pageId="172444475943172" minimized={false}/>
        </FacebookProvider> 
        <div id='privacy'>

          <section className="privacy ">
            <div className='pagetopMargin'></div>
            <div className="wrapper">
                <div className="flex flex-column">
                    <span className="quicklink-title">Privacy Policy</span>
                </div>
            </div>
            <div className="wrapper">
              <div className='privacy-content'>
                <div className='privacy-title'>Introduction</div>
                <br/>
                <div className='privacy-info'>Thank you for choosing QuickLink, a digital business card service (referred to as "we," "us," or "our"). At QuickLink, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our services.</div>
                <br/>
                <div className='privacy-title'>Information We Collect</div>
                <br/>
                <div className='privacy-info'>
                  <ul>
                    <li><strong>Personal Information:</strong> We may collect information such as your name, email address, phone number, and other contact information when you sign up for our service.</li>
                    <li><strong>Business Information:</strong> We may collect information related to your business, such as your job title, company name, and website.</li>
                    <li><strong>Usage Information:</strong> We may collect information about how you use our services, including log data, device information, and location information if you choose to provide it.</li>
                  </ul> 
                </div>
                <br/>
                <div className='privacy-title'>How We Use Your Information</div>
                <br/>
                <div className='privacy-info'>We may use your personal information for the following purposes:</div>
                <br/>
                <div className='privacy-info'>
                  <ul>
                    <li>To provide and improve our services.</li>
                    <li>To personalize your experience and make it more relevant.</li>
                    <li>To communicate with you, including sending transactional and promotional messages.</li>
                    <li>To analyze usage patterns and improve our services.</li>
                    <li>To comply with legal requirements and protect our rights.</li>
                  </ul> 
                </div>
                <br/>
                <div className='privacy-title'>Information Sharing</div>
                <br/>
                <div className='privacy-info'>We may share your information with third parties in the following situations:</div>
                <br/>
                <div className='privacy-info'>
                  <ul>
                    <li>With your consent.</li>
                    <li>To service providers and business partners who assist us in delivering our services.</li>
                    <li>In response to legal requests, court orders, or to comply with legal obligations.</li>
                  </ul> 
                </div>
                <br/>
                <div className='privacy-title'>Data Security</div>
                <br/>
                <div className='privacy-info'>We take the security of your information seriously. We implement technical and organizational measures to protect your data against unauthorized access or alteration.</div>
                <br/>
                <div className='privacy-title'>Your Choices</div>
                <br/>
                <div className='privacy-info'>You have the right to:</div>
                <br/>
                <div className='privacy-info'>
                  <ul>
                    <li>Access, correct, update, or delete your personal information.</li>
                    <li>Opt-out of receiving marketing communications.</li>
                    <li>Close your account.</li>
                  </ul> 
                </div>
                <br/>
                <div className='privacy-title'>Changes to This Policy</div>
                <br/>
                <div className='privacy-info'>We may update this Privacy Policy to reflect changes to our information practices. If we make significant changes, we will notify you.</div>
                <br/>
                <div className='privacy-title'>Contact Us</div>
                <br/>
                <div className='privacy-info'>If you have any questions, concerns, or requests related to your privacy or this Privacy Policy, please contact us.</div>
         
              </div>
            </div>
            <div className='margin-bot'></div>
          </section>

        </div>
        </ThemeProvider>
        
    )
}

export default Privacy;