import { createClient } from 'contentful'


const useContentfullAko = () => {
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    environment: 'master',
    host: "cdn.contentful.com"
  });
  const getClients = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "ako",
        select: "fields",
      });

      const sanitizeEntries = entries.items.map((item) => {
        
        return {
          ...item.fields
        }
      })
      // const sanitizeEntriesMedia = entries.items.map((item) => {
        
      //   return {
      //     ...item.fields
      //   }
      // })

      return sanitizeEntries
    } catch (error) {
      console.log(`Error fetching clients: ${error}`);
    }
  };
  return { getClients };
};

export default useContentfullAko

// import { contentful } from 'contentful'
// export const client = contentful({
//   space: process.env.REACT_APP_SPACE_ID,
//   accessToken: process.env.REACT_APP_ACCESS_TOKEN
// })