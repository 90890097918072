import React, { useState, useEffect, useRef } from 'react'
import './nav.scss'
import { BrowserRouter as Router, Switch, Route, Link, NavLink, Redirect } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import ReactGA from "react-ga4";
import ScrollToTop from 'react-router-scroll-top'

// import routing pages below
import Home from '../pages/home'
import PageNotFound from '../pages/404'
import Terms from '../pages/terms'
import Privacy from  '../pages/privacy'
import About from  '../pages/about'
import QuickLinkPage from '../pages/quicklink'
import IkawPage from '../pages/ikaw.js'
import AkoPage from '../pages/ako.js'

const MenuSyle = styled.div`

`

function Nav() {

    // theme configuartion with local storage
    const [theme, setTheme] = useState("light");
    const setMode = mode => {
        window.localStorage.setItem('theme', mode);
        setTheme(mode);
    }
    const themeToggler = () => {
        theme === 'light' ? setMode('dark') : setMode('light');
    }
    useEffect(() => {
        const localTheme = window.localStorage.getItem('theme')
        localTheme ? setTheme(localTheme) : setMode('light')
    }, [])


    const [menu, setMenu] = useState(false);
    const menuToggler = () => {
        menu === false ? setMenu(true) : setMenu(false)
    }


    return (
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />
        <Router>
        <ScrollToTop>
            <nav>
                <div className="wrapper">
                    <div className="nav-left">
                        {/* <div className='logo'>
                          <a href='https://www.design.jinsgonzales.com'>
                            <img src={logo} ></img>
                          </a>
                        </div>
                        <div className="main-links">
                        <ul className='nav-links'>
                            <NavLink className='link-name' activeClassName="active-route" exact to='/'><li>Home</li></NavLink>
                            <NavLink className='link-name' activeClassName="active-route" to='/services'><li>Services</li></NavLink>
                            <NavLink className='link-name' activeClassName="active-route" to='/about'><li>About</li></NavLink>
                        </ul>
                        </div> */}
                    </div>

                    <div className='nav-rightMobile'>
                      <div className="nav-right">
                          {/* <div className="main-link-contact">
                              <ul className='nav-links'>
                                  <NavLink className='link-name' activeClassName="active-route" exact to='/contact'><li>Contact</li></NavLink>
                              </ul>
                          </div> */}
                          <div className="mode" onClick={() => {themeToggler();}}>
                              <svg 
                              height="20" 
                              viewBox="0 0 30 30" 
                              fill="none" 
                              xmlns="http://www.w3.org/2000/svg">
                                  <path d="M15 0C6.70951 0 0 6.70881 0 15C0 23.2905 6.70881 30 15 30C23.2905 30 30 23.2912 30 15C30 6.70951 23.2912 0 15 0ZM15.8789 28.2137V1.78635C22.691 2.22955 28.2422 7.88777 28.2422 15C28.2422 22.1103 22.6942 27.7702 15.8789 28.2137Z" fill="white" />
                              </svg>
                          </div>
                      </div>
                      {/* <div className="menu">
                          <div className="mode neu" onClick={() => {menuToggler(); menuButtonClick()}}>
                              <svg 
                              height="20" 
                              viewBox="0 0 33 33" 
                              fill="none" 
                              xmlns="http://www.w3.org/2000/svg">
                                  <path d="M33 23.8333H0V27.5H33V23.8333Z" fill="black"/>
                                  <path d="M33 14.6667H0V18.3334H33V14.6667Z" fill="black"/>
                                  <path d="M33 5.5H0V9.16669H33V5.5Z" fill="black"/>
                              </svg>
                          </div>
                      </div> */}
                    </div>
                    
                </div>
            </nav>


            <div className="backdrop">
                <div className={menu ? 'menu-open': 'menu-closed'}>
                    <div className={menu ? 'menu-panel': 'menu-panel-none'}>
                        
                        <div className="menu-top ">
                            <div className="menu-top-link ">
                                
                                <div className="menu-top-in neu">
                                    <NavLink className="menu-nav-link" onClick={() => menuToggler()} exact to='/'>
                                        <div className="each-menu">
                                            <span>Home</span>
                                            <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                                            </svg>
                                        </div>
                                    </NavLink>

                                    <hr />

                                    <NavLink className="menu-nav-link" onClick={() => menuToggler()} to='/services'>
                                        <div className="each-menu">
                                            <span>Services</span>
                                            <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                                            </svg>
                                        </div>
                                    </NavLink>

                                    <hr />

                                    <NavLink className="menu-nav-link" onClick={() => menuToggler()} to='/about'>
                                    <div className="each-menu">
                                        <span>About</span>
                                        <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                                        </svg>
                                    </div>
                                    </NavLink>
                                </div>

                                <div className="menu-bot-in neu">
                                    <NavLink className="menu-nav-link" onClick={() => menuToggler()} exact to='/contact'>
                                        <div className="each-menu">
                                            <span>Contact</span>
                                            <svg height="15" viewBox="0 0 42 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M41.1493 33.4018L5.73415 1.12786C4.572 -0.0283801 2.68725 -0.0283801 1.52511 1.12786C0.362968 2.28402 0.362968 4.16001 1.52511 5.31626L34.8049 35.6465L1.52806 65.9767C0.365916 67.1329 0.365916 69.0089 1.52806 70.1651C2.6902 71.3212 4.57495 71.3212 5.73701 70.1651L41.1522 37.8911C41.7716 37.2747 42.0372 36.4576 41.9958 35.6495C42.0343 34.8383 41.7689 34.0213 41.1493 33.4018Z" fill="black" />
                                            </svg>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={menu ? 'closed': 'open'}>
                    <Switch>
                        <Route path='/' exact render={() => <About theme={theme} />}/>
                        <Route path='/terms-and-conditions' exact render={() => <Terms theme={theme} />}/>
                        <Route path='/privacy-policy' exact render={() => <Privacy theme={theme} />}/>
                        <Route path='/account-settings' exact render={() => <Home theme={theme} />}/>


                        <Route path="/ikaw/:id" render={() => <IkawPage theme={theme} />} />
                        <Route path="/ako/:id" render={() => <AkoPage theme={theme} />} />
                        <Route path="/:id" render={() => <QuickLinkPage theme={theme} />} />

                        <Route path='/404' render={() => <PageNotFound theme={theme} />} />
                        <Redirect to="/404" />
                    </Switch>
                </div>
                
                <footer className={menu ? 'closed': 'open'}>
                    <section className="footer section-footer">
                        <div className="wrapper">
                          <div className='footer-holder'>
                            <div className='footer-left'>
                              <div class="footer-grid">
                                <div className='copyright'>
                                  <div className='info'>
                                    <span>© 2024 <strong>QuickLink</strong></span>
                                    {/* <span>© 2023 SmarTap</span> */}
                                    <span>Developed by
                                      <a target="_blank" rel="noopener noreferrer" href="https://design.jinsgonzales.com"> Jins Design</a>
                                    </span>
                                    <span>
                                      <NavLink className='nav' exact to='/terms-and-conditions'>
                                      <span className='terms'>Terms & Conditions</span>
                                      </NavLink>
                                      <span> • </span>
                                      <NavLink className='nav' exact to='/privacy-policy'>
                                      <span className='privacy'>Privacy Policy</span>
                                      </NavLink>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          

                        </div>
                    </section>
                </footer>
            </div>
        </ScrollToTop>
        </Router>
        </ThemeProvider>
        
    )
}


export default Nav;
