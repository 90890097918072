import { createGlobalStyle } from 'styled-components'
import styled, { keyframes } from 'styled-components'

export const lightTheme = {
    hr: '0.5px solid rgba(0,0,0,0.09)',
    hr2: '1px solid rgba(0,0,0,0.09)',
    vl: '1px solid #F5F5F5',
    fill: '#F5F5F5',
    dash: '3px dashed white;',
    footer: '#EEEEEE !important',
    footer2: '#dfdfdf !important',
    info: "#c2c2c2 !important",
    mainBg: '#F5F5F5 !important',
    transparentBg: 'transparent !important',
    mainBgReverse: '#363636 !important',
    mainTextFooter: '#696969 !important',
    mainText: '#363636 !important',
    mainTextRevert: '#F5F5F5 !important',
    mainBorder: '1px solid #696969',
    navShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
    navShadowTransparent: 'transparent',
    neuShadow: '-1px -2px 1px rgba(255, 255, 255, 1), 1px 1px 3px rgba(0, 0, 0, 0.15) !important',
    neuShadowActive: 'inset -1px -2px 1px rgba(255, 255, 255, 1), inset 1px 1px 3px rgba(0, 0, 0, 0.15) !important',
    slider: '#F5F5F5',
    sliderText: '#363636'
}

export const darkTheme = {
    hr: '0.5px solid rgba(255, 255, 255, 0.09)',
    hr2: '1px solid rgba(255, 255, 255, 0.09)',
    vl: '1px solid #363636',
    fill: '#363636',
    dash: '3px dashed #363636;',
    footer: '#313131 !important',
    footer2: '#474747 !important',
    info: "#535353 !important",
    mainBg: '#363636 !important',
    transparentBg: 'transparent !important',
    mainBgReverse: '#F5F5F5 !important',
    mainTextFooter: '#a7a7a7 !important',
    mainText: '#F5F5F5 !important',
    mainRevert: '#363636 !important',
    mainBorder: '1px solid #a7a7a7',
    navShadow: '1px 1px 3px rgba(0, 0, 0, 0.50)',
    navShadowTransparent: 'transparent',
    neuShadow: '-1px -2px 1px rgba(255, 255, 255, 0.15), 1px 1px 3px rgba(0, 0, 0, 0.50) !important',
    neuShadowActive: 'inset -1px -2px 1px rgba(255, 255, 255, 0.15), inset 1px 1px 3px rgba(0, 0, 0, 0.50) !important',
    slider: '#363636',
    sliderText: '#F5F5F5'
}

let fill = true;

const fillAnimation = (props) => {
        return keyframes`
        from{
            fill: transparent;
        }
        to{
            fill: ${props}
        }
    `
}

const fillAnimationDelay = () => {
    if(fill === true) {
        setTimeout(function(){ fill = false; }, 3500);
        return `
        0.5s ease forwards 4.5s 
    `
    } else {
        return `
        0s ease forwards 0s 
    `
    }
}

const strokeStyle = (props) => {
    if(fill === true) {
        return props
    }
    else {
        // return `
        //     transparent
        // `
        return props
    }
}

export const GlobalStyles = createGlobalStyle`

    body {
        color: ${(props) => props.theme.mainText};
    }
    nav {
        background-color: ${(props) => props.theme.transparentBg};
        box-shadow: ${(props) => props.theme.navShadowTransparent};
    }
    section {
        background-color: ${(props) => props.theme.mainBg}
    }
    section .subtitle path,span {
        fill: ${(props) => props.theme.mainText};
    }
    .logo svg path {
      fill: ${(props) => props.theme.mainBg};
    }
    .motto {
      color: ${(props) => props.theme.mainBg};
    }
    .footer {
        background-color: ${(props) => props.theme.footer};
        color: ${(props) => props.theme.mainTextFooter};
    }
    .footer a{
        color: ${(props) => props.theme.mainTextFooter};
    }
    .footer svg path {
      fill: ${(props) => props.theme.mainTextFooter};
    }
    @media screen and (max-width: 480px) {
      .soc-medLink, .site-mapLink {
        border: ${(props) => props.theme.mainBorder};
      }
    }

    .hr-home {
        border: ${(props) => props.theme.hr}
    }


    .link-name {
        background-color: ${(props) => props.theme.mainBg};
        box-shadow : ${(props) => props.theme.neuShadow}
    }
    .active-route {
        box-shadow : ${(props) => props.theme.neuShadowActive}
    }
    .link-name:active {
        box-shadow : ${(props) => props.theme.neuShadowActive}
    }

    .mode {
      background-color: ${(props) => props.theme.footer};
    }
    .mode path {
      fill: ${(props) => props.theme.mainBgReverse};
    }
    .neu {
        box-shadow : ${(props) => props.theme.neuShadow}
    }

    .pagenotfound .quicklink-logo path {
      fill: ${(props) => props.theme.mainBgReverse};
    }
    


    #business-card .cover {
      background-color: ${(props) => props.theme.footer};
    }
    #business-card .dp img {
      border: 5px solid ${(props) => props.theme.mainBg};
    }
    #business-card .cover-quicklink .cover-bg {
      background-color: ${(props) => props.theme.mainBgReverse};
    }
    #business-card .cover-quicklink svg path {
      fill: ${(props) => props.theme.mainBg};
    }
    .content .add-contact-link {
      color: ${(props) => props.theme.mainBgReverse};
    }
    .content .add-contact, .pro-button {
      background-color: ${(props) => props.theme.footer2};
    }
    .content .add-contact-icon path {
      fill: ${(props) => props.theme.mainBgReverse};
    }
    .content .edit-contact-link, .help {
      color: ${(props) => props.theme.mainBgReverse};
    }
    .content .edit-contact {
      background-color: ${(props) => props.theme.footer2};
    }
    .content .more-options {
      background-color: ${(props) => props.theme.footer2};
    }
    .content .edit-contact-icon path {
      fill: ${(props) => props.theme.mainBgReverse};
    }
    .content .more-options-icon path {
      fill: ${(props) => props.theme.mainBgReverse};
    }
    .links .website {
      background-color: ${(props) => props.theme.footer};
    }
    .links a {
      color: ${(props) => props.theme.mainBgReverse};
    }
    .site-link .messenger path, .site-link .whatsapp path, .site-link .viber path, .site-link .telegram path{
      fill: ${(props) => props.theme.mainText};
    }
    .site-link .fb path, .site-link .ig path, .linkedin path, .twitter path, .youtube path, .spotify path, .tiktok path, .shopee path, .lazada path, .navigate path{
      fill: ${(props) => props.theme.mainText};
    }
    .sub {
      background-color: ${(props) => props.theme.footer2};
    }
    .website .hr {
      border: ${(props) => props.theme.hr2};
    }
    
    .terms-info, .privacy-info {
      color: ${(props) => props.theme.mainTextFooter};
    }
    strong {
      color: ${(props) => props.theme.mainText};
    }

    .footer a:hover {
      color: ${(props) => props.theme.mainText};
    }

    .loader-container {
      background-color: ${(props) => props.theme.mainBg};
    }
    .lds-ellipsis-all div {
      background-color: ${(props) => props.theme.mainBgReverse};
    }

    .help {
      background-color: ${(props) => props.theme.footer2};
    }
    .home-about .learn {
      background-color: ${(props) => props.theme.footer2};
    }

    .modal-bg {
      background-color: ${(props) => props.theme.footer2} !important;;
    }

    .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
      background-color: ${(props) => props.theme.footer};
      color: ${(props) => props.theme.mainText};
    }

    .features {
        background-color: ${(props) => props.theme.footer};
        /* color: ${(props) => props.theme.mainTextFooter}; */
    }
    .pair .logo svg path {
      fill: ${(props) => props.theme.mainText};
    }
    .pair .logo svg stroke {
      fill: ${(props) => props.theme.mainText};
    }

    .product-3d {
      background-color: ${(props) => props.theme.footer};
    }

    .helps {
      background-color: ${(props) => props.theme.mainBgReverse};
    }
    .slider {
      background-color: ${(props) => props.theme.mainBg};
    }
    .arrow_circle_right{
      color: ${(props) => props.theme.sliderText} !important;
    }
    .slide-to-activate{
      color: ${(props) => props.theme.slider} !important;
    }

    .exchange-form{
      background-color: ${(props) => props.theme.mainBg};
    }
    input, textarea {
      background-color: ${(props) => props.theme.footer};
      color: ${(props) => props.theme.mainText};
    }
    .send {
      background-color: ${(props) => props.theme.footer2};
      color: ${(props) => props.theme.mainText};
    }

    .help-me, .request-upgrade {
      background-color: ${(props) => props.theme.footer};
      color: ${(props) => props.theme.mainText};
    }
    .help-me a, .request-upgrade a{
      color: ${(props) => props.theme.mainText};
    }

    .upgrade-pro {
      background-color: ${(props) => props.theme.mainBg};
    }

`